@import "_reset";
@import "_fonts";
@import "_animations";
@import "_variables";

body {
    font-family: Muller, sans-serif;
    font-weight: normal;
    color: #000;
}

a {
    transition: color 200ms ease-in-out;
    color: inherit;

    &:hover {
        color: $red;
    }
}

.h1,.h2,.h3,.h4,.h5,.h6,h1,h2,h3,h4,h5,h6 {
    display: block;
    font-style: normal;
    color: inherit;
    text-rendering: optimizeLegibility;
    font-weight: 900;
    font-family: inherit
}

.h1, h1 {
    font-size: 3.125rem;
    margin-bottom: 1.875rem;
    line-height: 1;

    @media (max-width: 640px) {
        font-size: 2.2rem;
    }
}

.h2, h2 {
    font-size: 2.25rem;
    margin-bottom: 1.5625rem;
    line-height: 1;

    @media (max-width: 640px) {
        font-size: 1.7rem;
        margin-bottom: 0.9375rem;
    }
}

.h3,h3 {
    font-size: 1.5rem;
    font-weight: 800;
    line-height: 1.4;
    margin-top: 0;
    margin-bottom: 0.5rem;
}

.h5 {
    line-height: 1.2;
    font-size: 1.3rem;
    margin-bottom: 0.625rem;
    font-weight: 600
}

.h6, h6 {
    line-height: 1.2;
    font-size: 1.0625rem;
    margin-bottom: 0.625rem;
    font-weight: 600;
}

p {
    font-size: 1.125rem;
    line-height: 1.55;
    margin-bottom: 1.5rem;
}

label {
    display: block;
    margin: 0;
    font-size: .875rem;
    font-weight: normal;
    line-height: 1.8;
    color: #000;
}

ul, ol, dl {
    margin-bottom: 1rem;
    list-style-position: outside;
    line-height: 1.6;
}

ul {
    margin-left: 1.25rem;
}

[type="text"],[type="password"],[type="date"],[type="datetime"],[type="datetime-local"],[type="month"],[type="week"],[type="email"],[type="number"],[type="search"],[type="tel"],[type="time"],[type="url"],[type="color"],textarea,input:not([type]) {
    display: block;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    width: 100%;
    margin: 0 0 1rem;
    padding: 0.5rem;
    border: .0625rem solid #cacaca;
    border-radius: 0.25rem;
    background-color: #fff;
    font-size: 1rem;
    font-weight: normal;
    color: #000;
    -webkit-transition: border-color 0.25s ease-in-out, -webkit-box-shadow 0.5s;
    transition: border-color 0.25s ease-in-out, -webkit-box-shadow 0.5s;
    transition: box-shadow 0.5s, border-color 0.25s ease-in-out;
    transition: box-shadow 0.5s, border-color 0.25s ease-in-out, -webkit-box-shadow 0.5s;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    line-height: normal;
    height: 2.125rem
}

[type="text"]:active,[type="text"]:focus,[type="text"]:hover,[type="password"]:active,[type="password"]:focus,[type="password"]:hover,[type="date"]:active,[type="date"]:focus,[type="date"]:hover,[type="datetime"]:active,[type="datetime"]:focus,[type="datetime"]:hover,[type="datetime-local"]:active,[type="datetime-local"]:focus,[type="datetime-local"]:hover,[type="month"]:active,[type="month"]:focus,[type="month"]:hover,[type="week"]:active,[type="week"]:focus,[type="week"]:hover,[type="email"]:active,[type="email"]:focus,[type="email"]:hover,[type="number"]:active,[type="number"]:focus,[type="number"]:hover,[type="search"]:active,[type="search"]:focus,[type="search"]:hover,[type="tel"]:active,[type="tel"]:focus,[type="tel"]:hover,[type="time"]:active,[type="time"]:focus,[type="time"]:hover,[type="url"]:active,[type="url"]:focus,[type="url"]:hover,[type="color"]:active,[type="color"]:focus,[type="color"]:hover,textarea:active,textarea:focus,textarea:hover,input:not([type]):active,input:not([type]):focus,input:not([type]):hover {
    -webkit-box-shadow: none;
    box-shadow: none
}

.mobileMenuWrap {
    display: none;
    position: absolute;
    overflow: hidden;
    right: 0;
    top: 0;
    background-color: #000;
    width: 0;
    height: 100vh;
    z-index: 1;
    padding-top: 6.25rem;
    text-align: center;
    font-size: 1.25rem;
    -webkit-transition: width .3s, color .3s;
    transition: width .3s, color .3s;

    @media (max-width: 960px) {
        display: block;
    }

    &.open {
        width: 50vw;
        color: white;

        @media (max-width: 640px) {
            width: 100vw;
        }
    }
}

input {
    &.disabled {
        opacity: 0.6
    }

    &.error {
        background-color: #ffd0d0;
        border-color: $red;
    }
}

.react-tel-input {
    margin: 0 0 1rem;
    input {
        width: 100% !important;
    }
}

.grecaptcha-badge {
    visibility: hidden !important;
}